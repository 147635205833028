import './App.css';
import './Components/Navbar.js';
import Navbar from './Components/Navbar.js';
import AboutUs from './Components/AboutUs.js';
import ContactUs from './Components/ContactUs.js';
import WhatsappIntegration from './Components/Whatsapp.js';
import Menu from './Components/Menu.js';
import { isMobile } from 'react-device-detect';
import i18n from './i18n.js';

function App() {
  i18n.changeLanguage("gb");
  console.log(i18n);
  return (
    <div className="App">
      <Navbar></Navbar>
      <AboutUs></AboutUs>
      <Menu></Menu>
      <ContactUs></ContactUs> 
      <WhatsappIntegration></WhatsappIntegration>
      {isMobile ? 
      <div className="arrowUpCnt" onClick={()=>{
        document.getElementById("menuDetailsContainer").scrollIntoView();
      }}></div>
      : <div></div>}
    </div>
  );
}

export default App;
