import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import  styles from './AboutUs.module.css';
class AboutUs extends Component {
    constructor(props){  
        super(props);
        this.textDB = this.props.t;
        this.styles = styles;
        //this.labelClicked = this.labelClicked.bind(this); 
    }
    
    render() {
        return (
            <div className={this.styles.info} style={{width:"100%"}}>
                <div className={this.styles.backgroundImgHeader}>
                    <label><b>{this.textDB("aboutusdetails")}</b></label>
                </div>
                <div id="aboutUsDetailsContainer" className={this.styles.aboutUsDetailsContainer}>
                    <div id={this.styles.aboutUsDetailsCorner} className={this.styles.aboutUsDetailsContainerChild}>
                        <div className={this.styles.aboutUsDetailsTitleCnt}>
                            <label>{this.textDB("aboutusdetailsTitle")}</label>
                        </div>
                        <div className={this.styles.aboutUsDetailsText}>
                            <label>{this.textDB("aboutustext")}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(AboutUs); 