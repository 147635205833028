import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import styles from './MenuSection.module.css';

//salads
import shopska from '../images/menu/salads/shopska.png';
import zelenaQice from '../images/menu/salads/zelena-s-qice.png';
import gruckaSalad from '../images/menu/salads/grucka.png';
import ovcharskaSalad from '../images/menu/salads/ovcharska.png';
import kaprezeSalad from '../images/menu/salads/kapreze.png';
import ribatonSalad from '../images/menu/salads/zelena-s-ribaton.png';
import kyuopoloSalad from '../images/menu/salads/kuipolu.png';
import mlechnaSalad from '../images/menu/salads/mlechna.png';
import zeleMorkoviSalad from '../images/menu/salads/zele-s-morkovi.png';
import zeleMayoSalad from '../images/menu/salads/zele-s-morkovi-maioneza.png';
import katakSalad from '../images/menu/salads/katuk.png';
import pechenPiperSalad from '../images/menu/salads/pechen-piper.png';
import selskaSalad from '../images/menu/salads/selska.png';

//soups
import shkembeSoup from '../images/menu/soups/shkembe.png';
import pileshkaSoup from '../images/menu/soups/pileshka.png';
import gubenaKremSoup from '../images/menu/soups/gubena-krem-supa.png';
import domatenaKremSoup from '../images/menu/soups/domatena-krem-supa.png';
import agneshkaSoup from '../images/menu/soups/agneshka.png';
import beanSoup from '../images/menu/soups/bob.png';
import manatarkiSoup from '../images/menu/soups/manatarki.png';
import taratorSoup from '../images/menu/soups/tarator.png';

//breakfast
import englishBreakfast from '../images/menu/breakfast/angliska.png';
import mekitsaBreakfast from '../images/menu/breakfast/mekici.png';
import banitsaBreakfast from '../images/menu/breakfast/banica.png';
import purjeniFiliiBreakfast from '../images/menu/breakfast/purjeni-filii.png';

//starters
import halumiStarter from '../images/menu/hotstarters/sirene-halumi.png';
import nachosCheeseStarter from '../images/menu/hotstarters/nachos-kashkaval.png';
import pileshkiHapkiStarter from '../images/menu/hotstarters/pileshki-hapki.png';
import nachosKonKarneStarter from '../images/menu/hotstarters/nachos-chili-konkarne.png';
import pileKornfleksStarter from '../images/menu/hotstarters/pileshki-filenca-kornfleiks.png';
import kalmariStarter from '../images/menu/hotstarters/kalmari.png';
import skaridiStarter from '../images/menu/hotstarters/skaridi.png';
import skaridiSpicyStarter from '../images/menu/hotstarters/pikantni-skaridi.png';
import kashkavaleniHapkiStarter from '../images/menu/hotstarters/kashkavaleni-hapki.png';
import pileshkiKrilcaStarter from '../images/menu/hotstarters/pileshki-krilca.png';
import pechenKartofStarter from '../images/menu/hotstarters/pechen-kartof.png';
import lucheniKrugchetaStarter from '../images/menu/hotstarters/lucheni-krugcheta.png';
import mishMashStarter from '../images/menu/hotstarters/mish-mash.png';
import zadusheniZelenchuciStarter from '../images/menu/hotstarters/zadusheni-zelenchuci.png';
import gubiMasloStarter from '../images/menu/hotstarters/gubi-v-maslo.png';
import manatarkiMasloStarter from '../images/menu/hotstarters/manatarki-v-maslo.png';
import kashkavalPaneStarter from '../images/menu/hotstarters/kashkaval-pane.png';
import teleshkiEzikStarter from '../images/menu/hotstarters/teleshki-ezik-v-maslo.png';
import tikvichkiStarter from '../images/menu/hotstarters/tikvichki.png';
import chushkiDomatiStarter from '../images/menu/hotstarters/chuski-v-domaten-sos.png';
import chuskiBurekStarter from '../images/menu/hotstarters/chuska-burek.png';
import kamemberStarter from '../images/menu/hotstarters/kamember-s-borovinki.png';
import purjeniKartofiStarter from '../images/menu/hotstarters/purjeni-kartofi.png';
import pujeniKartofiSireneStarter from '../images/menu/hotstarters/purjeni-kartofi-sirene.png';
import kartofiSoteStarter from '../images/menu/hotstarters/kartofi-sote.png';
import sireneShopskiStarter from '../images/menu/hotstarters/sirene-po-shopski.png';

//egg dishes
import omeletNature from '../images/menu/eggdishes/omlet.png';
import qicaNaOchi from '../images/menu/eggdishes/qica-na-ochi.png';
import qicaPanagurski from '../images/menu/eggdishes/qica-po-panagurski.png';

//fish
import syomgaFish from '../images/menu/fish/siomga.png';
import pusturvaFish from '../images/menu/fish/pasturva.png';
import cipuraFish from '../images/menu/fish/cipura.png';
import skumriqFish from '../images/menu/fish/skumria.png';
import lavrakFish from '../images/menu/fish/lavrak.png';

//main dishes
import svinskaKavurma from '../images/menu/maindishes/svinska-kavurma.png';
import pileshkaKavurma from '../images/menu/maindishes/pileshka-kavurma.png';
import svinskiShitzel from '../images/menu/maindishes/svinski-vienski-shnitzel.png';
import sachSvinskoPileshko from '../images/menu/maindishes/sach-svinski-pileshko.png';
import svinskiDjolan from '../images/menu/maindishes/svinski-djolan-s-kartofi.png';
import agneshkoSurma from '../images/menu/maindishes/agneshko-s-drob-surma.png';
import svinskoFile from '../images/menu/maindishes/svinsko-file-s-guben-sos.png';
import pileBrokoli from '../images/menu/maindishes/pile-s-brokoli-smetana.png';
import lazanq from '../images/menu/maindishes/lazanq.png';
import pileKuri from '../images/menu/maindishes/pile-s-kuri.png';
import zelenchukovSach from '../images/menu/maindishes/zelenchukov-sach.png';
import fahitas from '../images/menu/maindishes/fahitas.png';
import pileShnitzel from '../images/menu/maindishes/pileshki-shnitzel.png';

//bbq
import kufte from '../images/menu/bbq/kufte.png';
import kebapche from '../images/menu/bbq/kebapche.png';
import svinskoShishce from '../images/menu/bbq/svinsko-shishche.png';
import tboneStek from '../images/menu/bbq/t-bone-stek.png';
import karnache from '../images/menu/bbq/karnatche.png';
import pileshkoShishce from '../images/menu/bbq/pileshko-shishce.png';
import stekFile from '../images/menu/bbq/stek-file.png';
import porkRibs from '../images/menu/bbq/svinski-rebra.png';
import ribaiStek from '../images/menu/bbq/ribai-stek.png';
import sudjuk from '../images/menu/bbq/sudjuk.png';
import vratnaPurjola from '../images/menu/bbq/svinska-vratna-purjola.png';
import pileshkaPurjola from '../images/menu/bbq/pileshka-purjola.png';
import pileshkoFile from '../images/menu/bbq/pileshko-file.png';
import zelenchuciNaSkara from '../images/menu/bbq/zelenchuci-na-skara.png';
import meshanaSkara from '../images/menu/bbq/meshana-skara.png';
import pileGrill from '../images/menu/bbq/pile-na-gril.png';

//side dish
import studenaGarnitura from '../images/menu/sidedishes/studena-garnitura.png';
import frenchFries from '../images/menu/sidedishes/purjeni-kartofi.png';
import potatoesSote from '../images/menu/sidedishes/kartofi-sote.png';
import kartofenoPure from '../images/menu/sidedishes/kartofeno-pure.png';
import oriz from '../images/menu/sidedishes/oriz.png';
import zadusheniZel from '../images/menu/sidedishes/zadusheni-zelenchuci.png';

//bread
import chesnovaPurlenka from '../images/menu/bread/chenova-purlenka.png';
import turskiHlqb from '../images/menu/bread/turski-hlqb.png';
import domashnaPitka from '../images/menu/bread/domashna-pitka.png';

//appetizers
import suhiMezeta from '../images/menu/appetizers/suhi-mezeta.png';
import assortedPlato from '../images/menu/appetizers/assorted-plato.png';
import platoSirena from '../images/menu/appetizers/plato-sirena.png';

//pasta
import boloneze from '../images/menu/pasta/boloneze.png';
import karbonara from '../images/menu/pasta/karbonora.png';
import pomodoro from '../images/menu/pasta/pomodoro.png';

//pizza
import kaprichoza from '../images/menu/pizza/kaprichoza.png';
import chetiriSezona from '../images/menu/pizza/4-sezona.png';
import havai from '../images/menu/pizza/havai.png';
import margarita from '../images/menu/pizza/margarita.png';
import peperoni from '../images/menu/pizza/peperoni.png';
import siciliana from '../images/menu/pizza/siciliana.png';
import quattroPizza from '../images/menu/pizza/quattro-formaji.png';

//toast and burgers
import teleshkiBurger from '../images/menu/toastsburgers/teleshki-burger.png';
import pileshkiBurger from '../images/menu/toastsburgers/pileshki-burger.png';

//desserts
import biskvitenaTorta from '../images/menu/desserts/biskvitena-torta.png';
import sufle from '../images/menu/desserts/sufle.png';

class MenuSection extends Component {
    constructor(props){  
        super(props);
        this.textDB = this.props.t;
        this.styles = styles; 
        this.menuData = {
            salads: [
                {
                    nameBG:"ШОПСКА САЛАТА",
                    nameUS:"SHOPSKA SALAD",
                    descriptionBG: "/домати,краставици,чушка,сирене,маслини/(7)",
                    descriptionUS: "/tomatoes,cucumbers,peppers,onions,cheese,olives/",
                    image: shopska,
                    price: "10,50",
                    grams: "350" 
                },
                {
                    nameBG:"ЗЕЛЕНА САЛАТА С ЯЙЦЕ",
                    nameUS:"GREEN SALAD WITH EGG",
                    descriptionBG: "/зелена салата,яйце,краставица,лук/(3)",
                    descriptionUS: "/lettuce,egg,cucumbers,onion/",
                    image: zelenaQice,
                    price: "11,00",
                    grams: "350" 
                },
                {
                    nameBG:"ГРЪЦКА САЛАТА",
                    nameUS:"GREEK SALAD",
                    descriptionBG: "/домати,краставици,чушка,сирене,маслини,червен лук,риган,зехтин/(7)",
                    descriptionUS: "/tomatoes,cucumbers,peppers,cheese,olives,onions,oregano,olive oil/",
                    image: gruckaSalad,
                    price: "13,00",
                    grams: "450" 
                },
                {
                    nameBG:"ОВЧАРСКА САЛАТА",
                    nameUS:"SHEEPHERD'S SALAD",
                    descriptionBG: "/домати,краставици,чушка,лук,шунка,кашкавал,гъби,яйце,сирене,маслини/(3,7)",
                    descriptionUS: "/tomatoes,cucumbers,peppers,onions,ham,yellow cheese,mushrooms,egg,cheese,olives/",
                    image: ovcharskaSalad,
                    price: "13,00",
                    grams: "450" 
                },
                {
                    nameBG:"КАПРЕЗЕ",
                    nameUS:"CAPRESE SALAD",
                    descriptionBG: "/домати,моцарела,песто,кедрови ядки/(7,8)",
                    descriptionUS: "/peeled tomatoes,mozzarella,pesto,pine nuts/",
                    image: kaprezeSalad,
                    price: "14,00",
                    grams: "350" 
                },
                {
                    nameBG:"ЗЕЛЕНА САЛАТА С РИБА ТОН",
                    nameUS:"LETTUCE WITH TUNA",
                    descriptionBG: "/зелена салата,маруля,царевица,риба тон/(4)",
                    descriptionUS: "/lettuce,corn,tuna fish/",
                    image: ribatonSalad,
                    price: "13,00",
                    grams: "350" 
                },
                {
                    nameBG:"КЪОПООЛУ",
                    nameUS:"KYUOPOLOU",
                    descriptionBG: "/патладжан,пипер,магданоз/",
                    descriptionUS: "/aubergine,peppers,garlic,parsley/",
                    image: kyuopoloSalad,
                    price: "10,00",
                    grams: "200" 
                },
                {
                    nameBG:"МЛЕЧНА САЛАТА",
                    nameUS:"YOGURT SALAD",
                    descriptionBG: "/изцедено мляко,краставици,копър,чесен/(7)",
                    descriptionUS: "/strained yogurt,cucumbers,dill,garlic/",
                    image: mlechnaSalad,
                    price: "10,00",
                    grams: "200" 
                },
                {
                    nameBG:"ЗЕЛЕ С МОРКОВИ",
                    nameUS:"CABBAGE AND CARROTS",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: zeleMorkoviSalad,
                    price: "7,50",
                    grams: "300" 
                },
                {
                    nameBG:"ЗЕЛЕ С МАЙОНЕЗА",
                    nameUS:"CABBAGE WITH MAYONNAISE",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: zeleMayoSalad,
                    price: "8,00",
                    grams: "" 
                },
                {
                    nameBG:"КАТЪК",
                    nameUS:"KATAK SALAD",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: katakSalad,
                    price: "10,00",
                    grams: "200" 
                },
                {
                    nameBG:"ПЕЧЕН ПИПЕР",
                    nameUS:"ROASTED PEPPERS",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: pechenPiperSalad,
                    price: "10,00",
                    grams: "300" 
                },
                {
                    nameBG:"СЕЛСКА САЛАТА",
                    nameUS:"COUNTRY SALAD",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: selskaSalad,
                    price: "17,00",
                    grams: "400" 
                },
            ],
            soups:[
                {
                    nameBG:"ШКЕМБЕ ЧОРБА",
                    nameUS:"TRIPE SOUP",
                    descriptionBG: "(7)",
                    descriptionUS: "(7)",
                    image: shkembeSoup,
                    price: "8,00",
                    grams: "300" 
                },
                {
                    nameBG:"ПИЛЕШКА СУПА",
                    nameUS:"CHICKEN SOUP",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: pileshkaSoup,
                    price: "7,00",
                    grams: "300" 
                },
                {
                    nameBG:"ГЪБЕНА КРЕМ СУПА",
                    nameUS:"MUSHROOM CREAM SOUP",
                    descriptionBG: "(1)",
                    descriptionUS: "(1)",
                    image: gubenaKremSoup,
                    price: "6,00",
                    grams: "300" 
                },
                {
                    nameBG:"ДОМАТЕНА КРЕМ СУПА",
                    nameUS:"TOMATO CREAM SOUP",
                    descriptionBG: "(1,7)",
                    descriptionUS: "(1,7)",
                    image: domatenaKremSoup,
                    price: "6,00",
                    grams: "300" 
                },
                {
                    nameBG:"АГНЕШКА СУПА",
                    nameUS:"LAMB SOUP",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: agneshkaSoup,
                    price: "8,00",
                    grams: "300" 
                },
                {
                    nameBG:"БОБ",
                    nameUS:"BEAN SOUP",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: beanSoup,
                    price: "6,00",
                    grams: "300" 
                },
                {
                    nameBG:"СУПА ОТ МАНАТАРКИ",
                    nameUS:"MUSHROOM SOUP",
                    descriptionBG: "(1)",
                    descriptionUS: "(1)",
                    image: manatarkiSoup,
                    price: "8,50",
                    grams: "300" 
                },
                {
                    nameBG:"ТАРАТОР",
                    nameUS:"TARATOR",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: taratorSoup,
                    price: "6,00",
                    grams: "300" 
                }
            ],
            breakfast: [
                {
                    nameBG:"АНГЛИЙСКА ЗАКУСКА",
                    nameUS:"ENGLISH BREAKFAST",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: englishBreakfast,
                    price: "20,00",
                    grams: "" 
                },
                {
                    nameBG:"МЕКИЦИ",
                    nameUS:"MEKITSA",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: mekitsaBreakfast,
                    price: "3/бр",
                    grams: "" 
                },
                {
                    nameBG:"БАНИЦА",
                    nameUS:"BANITSA",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: banitsaBreakfast,
                    price: "6,00",
                    grams: "" 
                },
                {
                    nameBG:"ПЪРЖЕНИ ФИЛИЙКИ",
                    nameUS:"FRENCH TOAST",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: purjeniFiliiBreakfast,
                    price: "3/бр",
                    grams: "" 
                }
            ],
            hotstarters: [
                {
                    nameBG:"ПЕЧЕНО СИРЕНЕ ХАЛУМИ",
                    nameUS:"GRILLED HALLOUMI CHEESE",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: halumiStarter,
                    price: "18,00",
                    grams: "250" 
                },
                {
                    nameBG:"НАЧОС С КАШКАВАЛ",
                    nameUS:"NACHOS WITH YELLOW CHEESE",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: nachosCheeseStarter,
                    price: "19,00",
                    grams: "300" 
                },
                {
                    nameBG:"ПИЛЕШКИ ХАПКИ",
                    nameUS:"CHICKEN BITES",
                    descriptionBG: "(1,3,7)",
                    descriptionUS: "(1,3,7)",
                    image: pileshkiHapkiStarter,
                    price: "21,00",
                    grams: "300" 
                },
                {
                    nameBG:"ННАЧОС ЧИЛИ КОН КАРНЕ",
                    nameUS:"NACHOS CHILLI CON CARNE",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: nachosKonKarneStarter,
                    price: "20,00",
                    grams: "350" 
                },
                {
                    nameBG:"ПИЛЕШКИ ФИЛЕНЦА С КОРНФЛЕЙКС",
                    nameUS:"CHICKEN FILLET WITH CORNFLAKES",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: pileKornfleksStarter,
                    price: "21,00",
                    grams: "300" 
                },
                {
                    nameBG:"КАЛМАРИ",
                    nameUS:"BREADED CALAMARI",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: kalmariStarter,
                    price: "22,00",
                    grams: "250" 
                },
                {
                    nameBG:"ПЛАТО СКАРИДИ",
                    nameUS:"PLATTER SHRIMPS",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: skaridiStarter,
                    price: "40,00",
                    grams: "500" 
                },
                {
                    nameBG:"ПИКАНТНИ СКАРИДИ",
                    nameUS:"SPICY SHRIMPS",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: skaridiSpicyStarter,
                    price: "22,00",
                    grams: "300" 
                },
                {
                    nameBG:"КАШКАВАЛЕНИ ХАПКИ",
                    nameUS:"YELLOW CHEESE BITES",
                    descriptionBG: "(1,3,7)",
                    descriptionUS: "(1,3,7)",
                    image: kashkavaleniHapkiStarter,
                    price: "19,00",
                    grams: "300" 
                },
                {
                    nameBG:"ПИЛЕШКИ КРИЛЦА",
                    nameUS:"CHICKEN WINGS",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: pileshkiKrilcaStarter,
                    price: "21,00",
                    grams: "300" 
                },
                {
                    nameBG:"ПЕЧЕН КАРТОФ",
                    nameUS:"JACKET POTATO",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: pechenKartofStarter,
                    price: "14,00",
                    grams: "300" 
                },
                {
                    nameBG:"ЛУЧЕНИ КРЪГЧЕТА",
                    nameUS:"ONION RINGS",
                    descriptionBG: "(1,3,7)",
                    descriptionUS: "(1,3,7)",
                    image: lucheniKrugchetaStarter,
                    price: "16,00",
                    grams: "200" 
                },
                {
                    nameBG:"МИШ-МАШ",
                    nameUS:"MISH MASH",
                    descriptionBG: "(3,7)",
                    descriptionUS: "(3,7)",
                    image: mishMashStarter,
                    price: "14,00",
                    grams: "300" 
                },
                {
                    nameBG:"ЗАДУШЕНИ ЗЕЛЕНЧУЦИ",
                    nameUS:"STEAMED VEGETABLES",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: zadusheniZelenchuciStarter,
                    price: "",
                    grams: "300" 
                },
                {
                    nameBG:"ГЪБИ В МАСЛО",
                    nameUS:"MUSHROOMS IN BUTTER",
                    descriptionBG: "(7)",
                    descriptionUS: "(7)",
                    image: gubiMasloStarter,
                    price: "",
                    grams: "200" 
                },
                {
                    nameBG:"МАНАТАРКИ В МАСЛО",
                    nameUS:"MUSHROOMS IN BUTTER",
                    descriptionBG: "(7)",
                    descriptionUS: "(7)",
                    image: manatarkiMasloStarter,
                    price: "",
                    grams: "200" 
                },
                {
                    nameBG:"КАШКАВАЛ ПАНЕ",
                    nameUS:"BREADED YELLOW CHEESE",
                    descriptionBG: "(1,3,7)",
                    descriptionUS: "(1,3,7)",
                    image: kashkavalPaneStarter,
                    price: "",
                    grams: "250" 
                },
                {
                    nameBG:"ТЕЛЕШКИ ЕЗИК В МАСЛО",
                    nameUS:"VEAL TONGUE IN BUTTER",
                    descriptionBG: "(7)",
                    descriptionUS: "(7)",
                    image: teleshkiEzikStarter,
                    price: "",
                    grams: "200" 
                },
                {
                    nameBG:"ТИКВИЧКИ",
                    nameUS:"ZUCCHINI",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: tikvichkiStarter,
                    price: "",
                    grams: "300" 
                },
                {
                    nameBG:"ЧУШКИ В ДОМАТЕН СОС",
                    nameUS:"PEPPERS IN TOMATO SAUCE",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: chushkiDomatiStarter,
                    price: "",
                    grams: "300" 
                },
                {
                    nameBG:"ЧУШКА БЮРЕК",
                    nameUS:"BREADED PEPPERS STUFFED WITH CHEESE",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: chuskiBurekStarter,
                    price: "",
                    grams: "350" 
                },
                {
                    nameBG:"КАМЕМБЕР С БОРОВИНКИ",
                    nameUS:"CAMEMBERT WITH BLUEBERRIES",
                    descriptionBG: "(7)",
                    descriptionUS: "(7)",
                    image: kamemberStarter,
                    price: "",
                    grams: "200" 
                },
                {
                    nameBG:"ПЪРЖЕНИ КАРТОФИ",
                    nameUS:"FRENCH FRIES",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: purjeniKartofiStarter,
                    price: "",
                    grams: "300" 
                },
                {
                    nameBG:"ПЪРЖЕНИ КАРТОФИ СЪС СИРЕНЕ",
                    nameUS:"FRENCH FRIES WITH CHEESE",
                    descriptionBG: "",
                    descriptionUS: "",
                    image: pujeniKartofiSireneStarter,
                    price: "",
                    grams: "300" 
                },
                {
                    nameBG:"КАРТОФИ СОТЕ",
                    nameUS:"SAUTEED POTATOES",
                    descriptionBG: "(7)",
                    descriptionUS: "(7)",
                    image: kartofiSoteStarter,
                    price: "",
                    grams: "300" 
                },
                {
                    nameBG:"СИРЕНЕ ПО ШОПСКИ",
                    nameUS:"SHOPSKI STYLE CHEESE",
                    descriptionBG: "(3,7)",
                    descriptionUS: "(3,7)",
                    image: sireneShopskiStarter,
                    price: "",
                    grams: "400" 
                }
            ],
            eggdishes: [
            {
                nameBG:"ОМЛЕТ НАТЮР",
                nameUS:"PLAIN OMELET",
                descriptionBG: "(3)",
                descriptionUS: "(3)",
                image: omeletNature,
                price: "9,00",
                grams: "200" 
            },
            {
                nameBG:"ОМЛЕТ АСОРТИ",
                nameUS:"ASSORTED OMELET",
                descriptionBG: "(3)",
                descriptionUS: "(3)",
                image: omeletNature,
                price: "13,00",
                grams: "250" 
            },
            {
                nameBG:"ЯЙЦА НА ОЧИ",
                nameUS:"POACHED EGGS",
                descriptionBG: "(3)",
                descriptionUS: "(3)",
                image: qicaNaOchi,
                price: "9,00",
                grams: "200" 
            },
            {
                nameBG:"ЯЙЦА ПО ПАНАГЮРСКИ",
                nameUS:"PANAGYURSKI STYLE EGGS",
                descriptionBG: "(3)",
                descriptionUS: "(3)",
                image: qicaPanagurski,
                price: "14,00",
                grams: "200" 
            }
            ],
            fish: [
        {
            nameBG:"СЪОМГА КОТЛЕТ",
            nameUS:"PANAGYURSKI STYLE EGGS",
            descriptionBG: "(4)",
            descriptionUS: "(4)",
            image: syomgaFish,
            price: "32,00",
            grams: "250" 
        },
        {
            nameBG:"ПЪСТЪРВА",
            nameUS:"TROUT",
            descriptionBG: "(4)",
            descriptionUS: "(4)",
            image: pusturvaFish,
            price: "20,00",
            grams: "300" 
        },
        {
            nameBG:"ЦИПУРА",
            nameUS:"SEA BREAM",
            descriptionBG: "(4)",
            descriptionUS: "(4)",
            image: cipuraFish,
            price: "30,00",
            grams: "400" 
        },
        {
            nameBG:"СКУМРИЯ",
            nameUS:"MACKEREL",
            descriptionBG: "(4)",
            descriptionUS: "(4)",
            image: skumriqFish,
            price: "18,00",
            grams: "300" 
        },
        {
            nameBG:"ЛАВРАК",
            nameUS:"SEA BASS",
            descriptionBG: "(4)",
            descriptionUS: "(4)",
            image: lavrakFish,
            price: "30,00",
            grams: "250" 
        }
            ],
            maindishes:[
        {
            nameBG:"СВИНСКА КАВЪРМА",
            nameUS:"PORK KAVARMA",
            descriptionBG: "/свинско месо,чушки,гъби,лук,домати/",
            descriptionUS: "/pork meat,peppers,mushrooms,onions,tomatoes/",
            image: svinskaKavurma,
            price: "26,00",
            grams: "400" 
        },
        {
            nameBG:"ПИЛЕШКА КАВЪРМА",
            nameUS:"CHICKEN KAVARMA",
            descriptionBG: "/пилешко месо,чушки,гъби,лук,домати/",
            descriptionUS: "/chicken,peppers,mushrooms,onions,tomatoes/",
            image: pileshkaKavurma,
            price: "26,00",
            grams: "400" 
        },
        {
            nameBG:"ВИЕНСКИ СВИНСКИ ШНИЦЕЛ",
            nameUS:"WIENER PORK SCHNITZEL",
            descriptionBG: "(1,3)",
            descriptionUS: "(1,3)",
            image: svinskiShitzel,
            price: "27,00",
            grams: "300" 
        },
        {
            nameBG:"САЧ СЪС СВИНСКО И ПИЛЕШКО МЕСО",
            nameUS:"SACH WITH PORK AND CHICKEN",
            descriptionBG: "/свинско месо,пилешко месо,чушки,гъби,кашкавал/(7)",
            descriptionUS: "/pork meat,chicken meat,peppers,mushrooms,yellow cheese/(7)",
            image: sachSvinskoPileshko,
            price: "40,00",
            grams: "600" 
        },
        {
            nameBG:"СВИНСКИ ДЖОЛАН С КАРТОФИ",
            nameUS:"PORK SHANK WITH POTATOES",
            descriptionBG: "",
            descriptionUS: "",
            image: svinskiDjolan,
            price: "30,00",
            grams: "800" 
        },
        {
            nameBG:"АГНЕШКО С ДРОБ СЪРМА",
            nameUS:"ROASTED LAMB WITH LIVER AND RICE",
            descriptionBG: "",
            descriptionUS: "",
            image: agneshkoSurma,
            price: "43,00",
            grams: "600" 
        },
        {
            nameBG:"СВИНСКО ФИЛЕ С ГЪБЕН СОС",
            nameUS:"PORK FILLET WITH MUSHROOMS",
            descriptionBG: "",
            descriptionUS: "",
            image: svinskoFile,
            price: "26,00",
            grams: "400" 
        },
        {
            nameBG:"ПИЛЕ С БРОКОЛИ И СМЕТАНА",
            nameUS:"CHICKEN WITH BROCCOLI AND CREAM",
            descriptionBG: "",
            descriptionUS: "",
            image: pileBrokoli,
            price: "30,00",
            grams: "400" 
        },
        {
            nameBG:"ЛАЗАНЯ",
            nameUS:"LASAGNA",
            descriptionBG: "",
            descriptionUS: "",
            image: lazanq,
            price: "30,00",
            grams: "400" 
        },
        {
            nameBG:"ПИЛЕ С КЪРИ",
            nameUS:"CHICKEN CURRY",
            descriptionBG: "",
            descriptionUS: "",
            image: pileKuri,
            price: "28,00",
            grams: "300" 
        },
        {
            nameBG:"ЗЕЛЕНЧУКОВ САч",
            nameUS:"VEGETABLE SACH",
            descriptionBG: "",
            descriptionUS: "",
            image: zelenchukovSach,
            price: "32,00",
            grams: "" 
        },
        {
            nameBG:"ФАХИТАС",
            nameUS:"FAJITAS",
            descriptionBG: "",
            descriptionUS: "",
            image: fahitas,
            price: "40,00",
            grams: "400" 
        },
        {
            nameBG:"ПИЛЕШКИ ШНИЦЕЛ",
            nameUS:"CHICKEN SCHNITZEL",
            descriptionBG: "(1,3)",
            descriptionUS: "(1,3)",
            image: pileShnitzel,
            price: "27,00",
            grams: "300" 
        }
            ],
            bbq:[
        {
            nameBG:"КЮФТЕ",
            nameUS:"MEATBALL",
            descriptionBG: "",
            descriptionUS: "",
            image: kufte,
            price: "4,00",
            grams: "100" 
        },
        {
            nameBG:"КЕБАПЧЕ",
            nameUS:"KEBAB",
            descriptionBG: "",
            descriptionUS: "",
            image: kebapche,
            price: "4,00",
            grams: "100" 
        },
        {
            nameBG:"СВИНСКО ШИШЧЕ",
            nameUS:"PORK SKEWER",
            descriptionBG: "",
            descriptionUS: "",
            image: svinskoShishce,
            price: "8,00",
            grams: "120" 
        },
        {
            nameBG:"ПИЛЕШКО ШИШЧЕ",
            nameUS:"CHICKEN SKEWER",
            descriptionBG: "",
            descriptionUS: "",
            image: pileshkoShishce,
            price: "8,00",
            grams: "120" 
        },
        {
            nameBG:"Т-БОУН СТЕК",
            nameUS:"T-BONE STEAK",
            descriptionBG: "",
            descriptionUS: "",
            image: tboneStek,
            price: "48,00",
            grams: "" 
        },
        {
            nameBG:"КАРНАЧЕ",
            nameUS:"SPIRAL SAUSAGE",
            descriptionBG: "",
            descriptionUS: "",
            image: karnache,
            price: "13,00",
            grams: "250" 
        }, 
        {
            nameBG:"СТЕК ФИЛЕ",
            nameUS:"FILLET STEAK",
            descriptionBG: "",
            descriptionUS: "",
            image: stekFile,
            price: "45,00",
            grams: "" 
        },
        {
            nameBG:"РЕБРА",
            nameUS:"PORK RIBS",
            descriptionBG: "",
            descriptionUS: "",
            image: porkRibs,
            price: "22,00",
            grams: "400" 
        },
        {
            nameBG:"РИБАЙ СТЕК",
            nameUS:"RIB-EYE STEAK",
            descriptionBG: "",
            descriptionUS: "",
            image: ribaiStek,
            price: "45,00",
            grams: "" 
        },
        {
            nameBG:"СУДЖУК",
            nameUS:"ROASTED SAUSAGE",
            descriptionBG: "",
            descriptionUS: "",
            image: sudjuk,
            price: "20,00",
            grams: "300" 
        },
        {
            nameBG:"СВИНСКА ВРАТНА ПЪРЖОЛА",
            nameUS:"PORK CHOP",
            descriptionBG: "",
            descriptionUS: "",
            image: vratnaPurjola,
            price: "20,00",
            grams: "300" 
        },
        {
            nameBG:"ПИЛЕШКА ПЪРЖОЛА ОТ БУТ",
            nameUS:"CHICKEN STEAK",
            descriptionBG: "",
            descriptionUS: "",
            image: pileshkaPurjola,
            price: "22,00",
            grams: "300" 
        },
        {
            nameBG:"ПИЛЕШКО ФИЛЕ",
            nameUS:"CHICKEN FILLET",
            descriptionBG: "",
            descriptionUS: "",
            image: pileshkoFile,
            price: "20,00",
            grams: "200" 
        },
        {
            nameBG:"ЗЕЛЕНЧУЦИ НА СКАРА",
            nameUS:"GRILLED VEGETABLES",
            descriptionBG: "",
            descriptionUS: "",
            image: zelenchuciNaSkara,
            price: "22,00",
            grams: "450" 
        },
        {
            nameBG:"МЕШАНА СКАРА",
            nameUS:"MIXED GRILL",
            descriptionBG: "",
            descriptionUS: "",
            image: meshanaSkara,
            price: "36,00",
            grams: "500" 
        },
        {
            nameBG:"ПИЛЕ НА ГРИЛ",
            nameUS:"GRILLED CHICKEN",
            descriptionBG: "",
            descriptionUS: "",
            image: pileGrill,
            price: "",
            grams: "1/2" 
        }
            ],
            sidedish:[
        {
            nameBG:"СТУДЕНА ГАРНИТУРА",
            nameUS:"COLD SIDE DISH",
            descriptionBG: "",
            descriptionUS: "",
            image: studenaGarnitura,
            price: "5,00",
            grams: "150" 
        },
        {
            nameBG:"ПЪРЖЕНИ КАРТОФИ",
            nameUS:"FRENCH FRIES",
            descriptionBG: "",
            descriptionUS: "",
            image: frenchFries,
            price: "5,00",
            grams: "150" 
        },
        {
            nameBG:"КАРТОФИ СОТЕ",
            nameUS:"SAUTEED POTATOES",
            descriptionBG: "",
            descriptionUS: "",
            image: potatoesSote,
            price: "5,00",
            grams: "150" 
        },
        {
            nameBG:"КАРТОФЕНО ПЮРЕ",
            nameUS:"MASHED POTATOES",
            descriptionBG: "",
            descriptionUS: "",
            image: kartofenoPure,
            price: "5,00",
            grams: "150" 
        },
        {
            nameBG:"ОРИЗ",
            nameUS:"RICE",
            descriptionBG: "",
            descriptionUS: "",
            image: oriz,
            price: "5,00",
            grams: "150" 
        },
        {
            nameBG:"ЗАДУШЕНИ ЗЕЛЕНЧУЦИ",
            nameUS:"STEAMED VEGETABLE",
            descriptionBG: "",
            descriptionUS: "",
            image: zadusheniZel,
            price: "6,00",
            grams: "150" 
        }
            ],
            bread:[
        {
            nameBG:"ДОМАШНА ПИТКА",
            nameUS:"HOMEMADE BREAD",
            descriptionBG: "",
            descriptionUS: "",
            image: domashnaPitka,
            price: "4,50",
            grams: "" 
        },
        {
            nameBG:"ЧЕСНОВА ПЪРЛЕНКА",
            nameUS:"GARLIC BREAD",
            descriptionBG: "(1)",
            descriptionUS: "(1)",
            image: chesnovaPurlenka,
            price: "7,00",
            grams: "200" 
        },
        {
            nameBG:"ТУРСКИ ХЛЯБ",
            nameUS:"TURKISH BREAD",
            descriptionBG: "(1)",
            descriptionUS: "(1)",
            image: turskiHlqb,
            price: "8,00",
            grams: "250" 
        },
        {
            nameBG:"ФИЛИЙКА ХЛЯБ",
            nameUS:"SLICE OF BREAD",
            descriptionBG: "",
            descriptionUS: "",
            image: "",
            price: "0,50",
            grams: "" 
        }
            ],
            appetizers:[
        {
            nameBG:"ЛУКАНКА",
            nameUS:"FLAT SAUSAGE",
            descriptionBG: "",
            descriptionUS: "",
            image: suhiMezeta,
            price: "13,00",
            grams: "100" 
        },
        {
            nameBG:"ПЛАТО СИРЕНА",
            nameUS:"ASSORTED CHEESE PLATTER",
            descriptionBG: "(7)",
            descriptionUS: "(7)",
            image: platoSirena,
            price: "45,00",
            grams: "300" 
        },
        {
            nameBG:"ФИЛЕ ЕЛЕНА",
            nameUS:"FILLET 'ELENA",
            descriptionBG: "",
            descriptionUS: "",
            image: suhiMezeta,
            price: "15,00",
            grams: "100" 
        },
        {
            nameBG:"ПЛАТО",
            nameUS:"ASSORTED PLATTER",
            descriptionBG: "(7)",
            descriptionUS: "(7)",
            image: assortedPlato,
            price: "45,00",
            grams: "" 
        },
        {
            nameBG:"ПАСТЪРМА",
            nameUS:"PASTRAMI",
            descriptionBG: "",
            descriptionUS: "",
            image: suhiMezeta,
            price: "15,00",
            grams: "100" 
        }
            ],
            pasta: [
        {
            nameBG:"БОЛОНЕЗЕ",
            nameUS:"BOLOGNESE",
            descriptionBG: "/доматен сос,лук,моркови,кайма,кашкавал/(1,7)",
            descriptionUS: "/tomato sauce,onion,carrots,minced meat,yellow cheese/(1,7)",
            image: boloneze,
            price: "16,00",
            grams: "400" 
        },
        {
            nameBG:"КАРБОНАРА",
            nameUS:"CARBONARA",
            descriptionBG: "/бекон,лук,гъби,яйце,сметана,кашкавал/(1,3,7)",
            descriptionUS: "/bacon,onion,mushrooms,egg,cream,yellow cheese/(1,3,7)",
            image: karbonara,
            price: "16,00",
            grams: "400" 
        },
        {
            nameBG:"ПОМОДОРО",
            nameUS:"POMODORO",
            descriptionBG: "/доматен сос,чесън/(1)",
            descriptionUS: "/tomato sauce,garlic/(1)",
            image: pomodoro,
            price: "14,50",
            grams: "350" 
        }
            ],  
            pizza: [
        {
            nameBG:"КАПРИЧОЗА",
            nameUS:"CAPRICCIOSA",
            descriptionBG: "/доматен сос,кашкавал,шунка,салам,пипер,яйце,маслини/(1,3,7)",
            descriptionUS: "/tomato sauce,yellow cheese,ham,salami,peppers,egg,olives/(1,3,7)",
            image: kaprichoza,
            price: "17,00",
            grams: "450" 
        },
        {
            nameBG:"ПЕПЕРОНИ",
            nameUS:"PEPPERONI",
            descriptionBG: "/доматен сос,пеперони,кашкавал/(1,3,7)",
            descriptionUS: "/tomato sauce,pepperoni,yellow cheese/(1,3,7)",
            image: peperoni,
            price: "16,00",
            grams: "400" 
        },
        {
            nameBG:"МАРГАРИТА",
            nameUS:"MARGARITA",
            descriptionBG: "/доматен сос,кашкавал/(1,3,7)",
            descriptionUS: "/tomato sauce,yellow cheese/(1,3,7)",
            image: margarita,
            price: "13,00",
            grams: "300" 
        },
        {
            nameBG:"ХАВАЙ",
            nameUS:"HAWAII",
            descriptionBG: "/доматен сос,ананас,шунка,кашкавал/",
            descriptionUS: "/tomato sauce,pineapple,ham,yellow cheese/",
            image: havai,
            price: "16,00",
            grams: "" 
        },
        {
            nameBG:"СИЦИЛИАНА",
            nameUS:"SICILIANA",
            descriptionBG: "/доматен сос,кисели краставички,шунка,луканка,кашкавал/(1,3,7)",
            descriptionUS: "/tomato sauce,pickles,ham,sausage,yellow cheese/(1,3,7)",
            image: siciliana,
            price: "16,00",
            grams: "400" 
        },
        {
            nameBG:"КАУТРО ФОРМАДЖИ",
            nameUS:"QUATTRO FORMAGGI",
            descriptionBG: "/доматен сос,четири сирена/",
            descriptionUS: "/tomato sauce,four cheeses/",
            image: quattroPizza,
            price: "17,00",
            grams: "400" 
        },
        {
            nameBG:"ЧЕТИРИ СЕЗОНА",
            nameUS:"QUATTRO STAGIONI",
            descriptionBG: "",
            descriptionUS: "",
            image: chetiriSezona,
            price: "17,00",
            grams: "" 
        }
            ],
            toastsandburgers:[
        {
            nameBG:"ТЕЛЕШКИ БУРГЕР",
            nameUS:"BEEF BURGER",
            descriptionBG: "(1,7)",
            descriptionUS: "(1,7)",
            image: teleshkiBurger,
            price: "16,00",
            grams: "400" 
        },
        {
            nameBG:"ПИЛЕШКИ БУРГЕР",
            nameUS:"CHICKEN BURGER",
            descriptionBG: "(1,7)",
            descriptionUS: "(1,7)",
            image: pileshkiBurger,
            price: "15,00",
            grams: "400" 
        },
        {
            nameBG:"ТОСТ С КАШКАВАЛ",
            nameUS:"TOAST WITH YELLOW CHEESE",
            descriptionBG: "(1,7)",
            descriptionUS: "(1,7)",
            image: "",
            price: "8,00",
            grams: "" 
        },
        {
            nameBG:"ТОСТ С ШУНКА И КАШКАВАЛ",
            nameUS:"TOAST WITH HAM AND YELLOW CHEESE",
            descriptionBG: "(1,7)",
            descriptionUS: "(1,7)",
            image: "",
            price: "10,00",
            grams: "" 
        }
            ],
            desserts: [
        {
            nameBG:"БИСКВИТЕНА ТОРТА",
            nameUS:"BISCUIT CAKE",
            descriptionBG: "",
            descriptionUS: "",
            image: biskvitenaTorta,
            price: "6,00",
            grams: "" 
        },
        {
            nameBG:"СУФЛЕ",
            nameUS:"SOUFFLE",
            descriptionBG: "",
            descriptionUS: "",
            image: sufle,
            price: "8,00",
            grams: "" 
        },
        {
            nameBG:"ПАЛАЧИНКИ",
            nameUS:"PANCAKE",
            descriptionBG: "/с шоколад / конфитюр / мед /(1,3,7)",
            descriptionUS: "/with chocolate / jam / honey /(1,3,7)",
            image: "",
            price: "5,00",
            grams: "250" 
        },
        {
            nameBG:"ОВЧЕ МЛЯКО",
            nameUS:"SHEEP YOGURT",
            descriptionBG: "с боровинки / мед (7)",
            descriptionUS: "/with blueberries / honey (7)",
            image: "",
            price: "7,00",
            grams: "250" 
        },
        {
            nameBG:"СЕЗОННИ ПЛОДОВЕ",
            nameUS:"SEASONAL FRUITS",
            descriptionBG: "",
            descriptionUS: "",
            image: "",
            price: "8,00",
            grams: "" 
        },
        {
            nameBG:"ДЕСЕРТ НА ДЕНЯ",
            nameUS:"DESSERT OF THE DAY",
            descriptionBG: "",
            descriptionUS: "",
            image: "",
            price: "",
            grams: "" 
        }
            ],
            drinks: {
                hotdrinks: [
                {
                    nameBG:"Кафе Еспресо",
                    nameEN:"Espresso",
                    ml:"60",
                    price: "0,00"
                },
                {
                    nameBG:"Безкофеиново кафе",
                    nameEN:"Espresso decaf",
                    ml:"60",  
                    price: "0,00"
                },
                {
                    nameBG:"Нескафе",
                    nameEN:"Nescafe",
                    ml:"60",  
                    price: "0,00"
                },
                {
                    nameBG:"Виенско кафе",
                    nameEN:"Weiner Melange",
                    ml:"80",  
                    price: "0,00"
                },
                {
                    nameBG:"Капучино",
                    nameEN:"Cappuccino",
                    ml:"180",  
                    price: "0,00"
                },
                {
                    nameBG:"Горещ шоколад",
                    nameEN:"Hot chocolate",
                    ml:"180",  
                    price: "0,00"
                },
                {
                    nameBG:"Мляко с нескафе",
                    nameEN:"Nescafe with milk",
                    ml:"180",  
                    price: "0,00"
                },
                {
                    nameBG:"Нескафе фрапе",
                    nameEN:"Nescafe frappe",
                    ml:"180",  
                    price: "0,00"
                },
                {
                    nameBG:"Горещо мляко",
                    nameEN:"Hot milk",
                    ml:"180",  
                    price: "0,00"
                },
                {
                    nameBG:"Чай",
                    nameEN:"Tea",
                    ml:"200",  
                    price: "0,00"
                },
                {
                    nameBG:"Айриш кафе",
                    nameEN:"Irish coffee",
                    ml:"150",  
                    price: "0,00"
                },
                {
                    nameBG:"Френч кафе",
                    nameEN:"French coffee",
                    ml:"150",  
                    price: "0,00"
                },
                {
                    nameBG:"Бейлис кафе",
                    nameEN:"Bailey's coffee",
                    ml:"150",  
                    price: "0,00"
                },
                {
                    nameBG:"Сметанка",
                    nameEN:"Coffee creamer",
                    ml:"1pcs.",  
                    price: "0,00"
                },
                {
                    nameBG:"Мед",
                    nameEN:"Honey",
                    ml:"1pcs.",  
                    price: "0,00"
                }
                ],          
                softdrinks: [
                {
                    nameBG:"Кока Кола",
                    nameEN:"Coca Cola",
                    ml:"250",
                    price: "0,00"
                },
                {
                    nameBG:"Кока Кола",
                    nameEN:"Coca Cola",
                    ml:"500",
                    price: "0,00"
                },
                {
                    nameBG:"Фанта",
                    nameEN:"Fanta",
                    ml:"250",
                    price: "0,00"
                },
                {
                    nameBG:"Спрайт",
                    nameEN:"Sprite",
                    ml:"250",
                    price: "0,00"
                },
                {
                    nameBG:"Кинли тоник",
                    nameEN:"Kinley tonic",
                    ml:"250",
                    price: "0,00"
                },
                {
                    nameBG:"Кинли трапезна вода",
                    nameEN:"Kinley table water",
                    ml:"250",
                    price: "0,00"
                },
                {
                    nameBG:"Студен чай",
                    nameEN:"Ice tea",
                    ml:"500",
                    price: "0,00"
                },
                {
                    nameBG:"Минерална вода",
                    nameEN:"Mineral water",
                    ml:"500",
                    price: "0,00"
                },
                {
                    nameBG:"Минерална вода",
                    nameEN:"Mineral water",
                    ml:"1500",
                    price: "0,00"
                },
                {
                    nameBG:"Айран",
                    nameEN:"Ayran",
                    ml:"250",
                    price: "0,00"
                },
                {
                    nameBG:"Ред Бул",
                    nameEN:"Red Bull",
                    ml:"250",
                    price: "0,00"
                },
                {
                    nameBG:"Лимонада",
                    nameEN:"lemonade",
                    ml:"300",
                    price: "0,00"
                },
                {
                    nameBG:"Натурален сок",
                    nameEN:"Juice",
                    ml:"200",
                    price: "0,00"
                },
                {
                    nameBG:"Фреш",
                    nameEN:"Fresh juice",
                    ml:"200",
                    price: "0,00"
                }
                ],
                brandydrinks:[
                {
                    nameBG:"Карнобатска Мускатова",
                    nameEN:"Karnobat Muskat brandy",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Поморийска Мускатова",
                    nameEN:"Pomoriyska Muskat brandy",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Поморийска Специална",
                    nameEN:"Pomoriyska Special brandy",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Сливенска перла",
                    nameEN:"Sliven's Pearl",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Сливенска перла 12г.",
                    nameEN:"Sliven's Pearl 12 years old brandy",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Бургаска Мускатова",
                    nameEN:"Burgas Muskat brandy",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Бургас 63",
                    nameEN:"Burgas 63",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Бургас 63 Барел",
                    nameEN:"Burgas 63 Barrel",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Троянска сливова",
                    nameEN:"Troyan plum brandy",
                    ml:"",
                    price: "0,00"
                },
                {
                    nameBG:"Стралджанска",
                    nameEN:"Straldjanska brandy",
                    ml:"",
                    price: "0,00"
                }
                ],
                beerdrinks: [
                {
                    nameBG:"Наливна Каменица",
                    nameEN:"Draught Kamenitza",
                    ml:"330",
                    price: "0,00"
                },
                {
                    nameBG:"Наливна Каменица",
                    nameEN:"Draught Kamenitza",
                    ml:"500",
                    price: "0,00"
                },
                {
                    nameBG:"Старопрамен",
                    nameEN:"Staropramen",
                    ml:"500",
                    price: "0,00"
                },
                {
                    nameBG:"Бекс",
                    nameEN:"Beck's",
                    ml:"330",
                    price: "0,00"
                },
                {
                    nameBG:"Стела Артова",
                    nameEN:"Stella Artois",
                    ml:"330",
                    price: "0,00"
                },
                {
                    nameBG:"Каменица",
                    nameEN:"Kamenitza",
                    ml:"500",
                    price: "0,00"
                },
                {
                    nameBG:"Бургаско",
                    nameEN:"Burgasko",
                    ml:"500",
                    price: "0,00"
                },
                {
                    nameBG:"Лефе",
                    nameEN:"Leffe",
                    ml:"330",
                    price: "0,00"
                },
                {
                    nameBG:"Хайнекен",
                    nameEN:"Heineken",
                    ml:"330",
                    price: "0,00"
                },
                {
                    nameBG:"Корона",
                    nameEN:"Corona",
                    ml:"355",
                    price: "0,00"
                },
                {
                    nameBG:"Безалкохолна бира Стела Артоа",
                    nameEN:"Non-alcoholic beer Stella Artois",
                    ml:"330",
                    price: "0,00"
                }
                ],
                spiritdrinks: [
                {
                    nameBG:"Водка Савой",
                    nameEN:"Vodka Savoy",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Водка Финландия",
                    nameEN:"Vodka Finlandia",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Водка Абсолют",
                    nameEN:"Absolut",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Водка Смирноф",
                    nameEN:"Smirnoff",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Водка Руски Стандарт",
                    nameEN:"Russian Standard",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Водка Белуга",
                    nameEN:"Vodka Beluga",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Джин Савой",
                    nameEN:"Gin Savoy",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Джин Гордънс",
                    nameEN:"Gin Gordon's",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Бифитър",
                    nameEN:"Beefeater",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Текила Савой",
                    nameEN:"Tequila Savoy",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Текила внос",
                    nameEN:"Imported tequila",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Ром Савой(бял,тъмен)",
                    nameEN:"Rum Savoy(white,dark)",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Бакарди",
                    nameEN:"Bacardi",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Бренди Карнобат",
                    nameEN:"Brandy Karnobat",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Коняк Плиска",
                    nameEN:"Cognac Pliska",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Метакса",
                    nameEN:"Metaxa",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Коняк Хенеси",
                    nameEN:"Cognac Hennessy",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Пломарри",
                    nameEN:"Plomari",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Мастика",
                    nameEN:"Mastika",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Мента",
                    nameEN:"Mint",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Уиски Савой",
                    nameEN:"Savoy whiskey",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Уиски Джони Уокър",
                    nameEN:"Johnnie Walker",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Джим Бийм",
                    nameEN:"Jim Beam",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Пади",
                    nameEN:"Paddy",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Джеймисън",
                    nameEN:"Jameson",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Бушмилс",
                    nameEN:"Bushmills",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Тюламор Дю",
                    nameEN:"Tullamore Dew",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Джей енд Би",
                    nameEN:"J&B",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Уиски 12 годишно",
                    nameEN:"Whiskey 12 years old",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Джак Даниелс",
                    nameEN:"Jack Daniels",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Вермут",
                    nameEN:"Vermouth",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Мартини",
                    nameEN:"Martini",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Бейлис",
                    nameEN:"Baileys",
                    ml:"50",
                    price: "0,00"
                },
                {
                    nameBG:"Йегермайстер",
                    nameEN:"Jegermeister",
                    ml:"50",
                    price: "0,00"
                },
                ],
            },
            alergens:{
                bg: [
                "1. Зърнени култури, съдържащи глутен (пшеница и нейните разновидности шпелта,камут или техните хибриди, ръж, ечемик, овес), както и продуктите от тях;",
                "2. Ракообразни и продукти от тях (скариди, раци, крабове и т.н.);",
                "3. Яйца и продуктите от тях;",
                "4. Риба и рибни продуктиот тях;",
                "5. Фъстци и продукти от тях;",
                "6. Соя и соеви продукти;",
                "7. Мляко и млечни продукти (включително лактоза);",
                "8. Различни видове ядки (бадеми, лешници, орехи, кашу, пеканови ядки, бразилски орехи, шамфъстък, орех макадемия или орех Куинсленд) и продукти от тях;",
                "9. Целина и продукти от нея;",
                "10. Синап и продукти от него;",
                "11. Сусам и продукти от него;",
                "12. Серен двуокис или сулфити с концентрация над 10 мг/кг или 10 мг/л изразени под формата на общ SO2;",
                "13. Лупина и продукти от нея;",
                "14. Мекотели и продукти от тях (миди, стриди, октоподи, охльови и т.н.);"
                ],
                en: [
                "1. Cereals containing gluten, namely: wheat (such as spelt and khorasan wheat), rye barley, oats or their hybridised strains and products thereof;",
                "2. Crustaceans and products thereof;",
                "3. Eggs and products thereof;",
                "4. Fish and products thereof;",
                "5. Peanuts and products thereof;",
                "6. Soybeans and products thereof;",
                "7. Milk and products thereof(including lactose);",
                "8. Nuts namely: almods, hazelnuts, walnuts, cashews,pecannuts, Brazil nuts, pistachio nuts, macademia or Queensland nuts and products thereof;",
                "9. Celery and products thereof;",
                "10. Mustard and products thereof;",
                "11. Sesame seeds and products thereof;",
                "12. Sulphur dioxide and sulphites at concentrations of more than 10 mg/kg or 10mg/litre in terms of the total SO2;",
                "13. Lupin and products thereof;",
                "14. Molluscs and products thereof;"
                ]
            }   
        }   
    }

    render (){
        let contentWidth;
        isMobile ? contentWidth = "100%" : contentWidth = "50%";
        return(
            <div>
                <div className="row">
                    <div className={this.styles.sectionFlag} style={{backgroundImage:"url("+this.props.flag+")"}}>
                        <label>{this.props.i18n.language === "gb" ? this.props.titleEN : this.props.titleBG }</label>
                    </div> 
                </div>
                {this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].length > 0  ? 
                        this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].map((element, i) => {
                            return(
                                <div key={i}>                                    
                                    <div className={this.styles.itemContainer} style={{width:contentWidth}}>
                                        <div className={this.styles.itemInnerContainer}>
                                            <div>
                                                <div className={this.styles.itemImageContainer}>
                                                    <div className={this.styles.itemImage} style={{backgroundImage:"url("+element.image+")"}}></div>
                                                </div>
                                                <div className={this.styles.itemDetails}>
                                                    <div className={this.styles.itemTitle}>
                                                        {this.props.i18n.language === "gb" ? 
                                                            <label><strong>{element.nameUS}</strong></label> 
                                                        : 
                                                            <label><strong>{element.nameBG}</strong></label> 
                                                        }
                                                    </div>
                                                    <div className={this.styles.itemDescription}>
                                                        &nbsp;
                                                        {this.props.i18n.language === "gb" ? 
                                                            <label>{element.descriptionUS}</label>
                                                        :
                                                            <label>{element.descriptionBG}</label>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>  
                                                <div className={this.styles.itemPriceGramsContainer} style={{textAlign:"left"}}>
                                                        {element.price.toString().includes(",") ? 
                                                            <label>
                                                                {element.price.toString().substring(0, element.price.toString().indexOf(","))}
                                                                <sup>
                                                                    {element.price.toString().substring( element.price.toString().indexOf(','),element.price.toString().length)}
                                                                </sup> 
                                                                лв./BGN
                                                            </label>
                                                            :
                                                            <label>{element.price} лв./BGN</label>
                                                        }
                                                </div>
                                                <div className={this.styles.itemPriceGramsContainer} style={{textAlign:"right"}}>
                                                    <label>{element.grams.length > 0 ? element.grams+"g" : ""}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    :       
                        <div>
                            {this.props.titleEN.split(" ").join("").toLocaleLowerCase() === "drinks" ? 
                                <div style={{overflow:"auto"}}>
                                    <div className={this.styles.drinksContainer} style={{width:contentWidth}}>
                                        <div className={this.styles.drinksMarginBottom}>
                                            <label>{this.textDB("menuDrinksHotDrinks")}</label>
                                        </div>
                                        <div>
                                            {this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].hotdrinks.map((element,i)=>{
                                                return(
                                                    <div key={i}>
                                                        <div className={this.styles.drinksName}>
                                                            <label>{this.props.i18n.language === "gb" ? element.nameEN : element.nameBG}</label>
                                                        </div>
                                                        <div className={this.styles.drinksDotted}></div>
                                                        <div className={this.styles.drinksMl} >
                                                            <label>{element.ml}ml</label>
                                                        </div>
                                                        <div className={this.styles.drinksPrice}>
                                                            <label>{element.price}</label>
                                                        </div>
                                                        <div className={this.styles.drinksPriceType}>
                                                            <label>лв./BGN</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={this.styles.drinksContainer} style={{width:contentWidth,minHeight:"500px"}}>
                                        <div className={this.styles.drinksMarginBottom}>
                                            <label>{this.textDB("menuDrinksSoftDrinks")}</label>
                                        </div>
                                        <div>
                                            {this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].softdrinks.map((element,i)=>{
                                                return(
                                                    <div key={i} style={{textAlign:"left"}}>
                                                        <div className={this.styles.drinksName}>
                                                            <label>{this.props.i18n.language === "gb" ? element.nameEN : element.nameBG}</label>
                                                        </div>
                                                        <div className={this.styles.drinksDotted}></div>
                                                        <div className={this.styles.drinksMl} >
                                                            <label>{element.ml}ml</label>
                                                        </div>
                                                        <div className={this.styles.drinksPrice}>
                                                            <label>{element.price}</label>
                                                        </div>
                                                        <div className={this.styles.drinksPriceType}>
                                                            <label>лв./BGN</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={this.styles.drinksContainer} style={{width:contentWidth}}>
                                        <div className={this.styles.drinksMarginBottom}>
                                            <label>{this.textDB("menuDrinksBrandy")}</label>
                                        </div>
                                        <div>
                                            {this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].brandydrinks.map((element,i)=>{
                                                return(
                                                    <div key={i} style={{textAlign:"left"}}>
                                                        <div className={this.styles.drinksName}>
                                                            <label>{this.props.i18n.language === "gb" ? element.nameEN : element.nameBG}</label>
                                                        </div>
                                                        <div className={this.styles.drinksDotted}></div>
                                                        <div className={this.styles.drinksMl} >
                                                            <label>{element.ml}ml</label>
                                                        </div>
                                                        <div className={this.styles.drinksPrice}>
                                                            <label>{element.price}</label>
                                                        </div>
                                                        <div className={this.styles.drinksPriceType}>
                                                            <label>лв./BGN</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={this.styles.drinksContainer} style={{width:contentWidth,minHeight:"500px"}}>
                                        <div className={this.styles.drinksMarginBottom}>
                                            <label>{this.textDB("menuDrinksBeer")}</label>
                                        </div>
                                        <div>
                                            {this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].beerdrinks.map((element,i)=>{
                                                return(
                                                    <div key={i} style={{textAlign:"left"}}>
                                                        <div className={this.styles.drinksName}>
                                                            <label>{this.props.i18n.language === "gb" ? element.nameEN : element.nameBG}</label>
                                                        </div>
                                                        <div className={this.styles.drinksDotted}></div>
                                                        <div className={this.styles.drinksMl} >
                                                            <label>{element.ml}ml</label>
                                                        </div>
                                                        <div className={this.styles.drinksPrice}>
                                                            <label>{element.price}</label>
                                                        </div>
                                                        <div className={this.styles.drinksPriceType}>
                                                            <label>лв./BGN</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={this.styles.drinksContainer} style={{width:contentWidth}}>
                                        <div className={this.styles.drinksMarginBottom}>
                                            <label>{this.textDB("menuDrinksSpirits")}</label>
                                        </div>
                                        <div>
                                            {this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].spiritdrinks.map((element,i)=>{
                                                return(
                                                    <div key={i} style={{textAlign:"left"}}>
                                                        <div className={this.styles.drinksName}>
                                                            <label>{this.props.i18n.language === "gb" ? element.nameEN : element.nameBG}</label>
                                                        </div>
                                                        <div className={this.styles.drinksDotted}></div>
                                                        <div className={this.styles.drinksMl} >
                                                            <label>{element.ml}ml</label>
                                                        </div>
                                                        <div className={this.styles.drinksPrice}>
                                                            <label>{element.price}</label>
                                                        </div>
                                                        <div className={this.styles.drinksPriceType}>
                                                            <label>лв./BGN</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            : <div></div>}
                            {this.props.titleEN.split(" ").join("").toLocaleLowerCase() === "alergens" ? 
                                <div>
                                    <label>{this.textDB("alergenTitle")}</label>
                                    <div style={{marginTop:"10px"}}>
                                        <label>{this.textDB("alergenDescription")}</label>
                                    </div>
                                    <div style={{textAlign:"left",marginTop:"20px",padding:"2%"}}>
                                        {this.props.i18n.language === "gb" ? 
                                            this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].en.map((element, i) => {
                                                return(
                                                    <div key={i}><label>{element}</label></div>
                                                )
                                            }) 
                                        : 
                                            this.menuData[this.props.titleEN.split(" ").join("").toLocaleLowerCase()].bg.map((element, i) => {
                                                return(
                                                    <div key={i}><label>{element}</label></div>
                                                )
                                            }) 
                                        }
                                    </div>
                                </div>
                            : <div></div>}
                        </div>
                        
                }
            </div>
        )
    }
}

export default withTranslation()(MenuSection); 