import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { withTranslation } from 'react-i18next';
import MenuSection from "./MenuSection";
import styles from './Menu.module.css';


import greenFlag from "../images/tabs/tab-green.svg";
import orangeFlag from "../images/tabs/tab-orange.svg";
import yellowFlag from '../images/tabs/tab-yellow.svg';
import darkYellowFlag from '../images/tabs/tab-darkYellow.svg';
import lightYellowFlag from '../images/tabs/tab-lightYellow.svg';
import darkGreenFlag from '../images/tabs/tab-darkGreen.svg';
import redFlag from '../images/tabs/tab-red.svg';
import darkRedFlag from '../images/tabs/tab-darkRed.svg';
import pinkFlag from '../images/tabs/tab-pink.svg';
import grayFlag from '../images/tabs/tab-gray.svg';
import lightPinkFlag from '../images/tabs/tab-lightPink.svg';
import darkGrayFlag from '../images/tabs/tab-darkGray.svg';
import deepRedFlag from '../images/tabs/tab-deepRed.svg';
import brownFlag from '../images/tabs/tab-brown.svg';
import { isDesktop, isMobile } from "react-device-detect";


class Menu extends Component {
    constructor(props){  
        super(props);
        this.textDB = this.props.t;
        this.styles = styles;   
        this.onSubMenuSelected = this.onSubMenuSelected.bind(this);
        this.carouselSettings = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 5
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 3
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 3
            }
      }

        this.menuSections =[
          {
            titleEN:"Salads",
            titleBG:"Салати",
            flag:greenFlag,
          },
          {
            titleEN:"Soups", 
            titleBG:"Супи",
            flag:orangeFlag
          },
          {
            titleEN:"Breakfast", 
            titleBG:"Закуски",
            flag:yellowFlag
          },
          {
            titleEN:"Hot Starters", 
            titleBG:"Предястия",
            flag:darkYellowFlag
          },
          {
            titleEN:"Egg Dishes",
            titleBG:"Ястия с Яйце", 
            flag:lightYellowFlag
          },
          {
            titleEN:"Fish",
            titleBG:"Риба", 
            flag:darkGreenFlag
          },
          {
            titleEN:"Main Dishes", 
            titleBG:"Основни Ястия", 
            flag:redFlag
          },
          {
            titleEN:"BBQ",
            titleBG:"Скара", 
            flag:darkRedFlag
          },
          {
            titleEN:"Side Dish",
            titleBG:"Гарнитури",
            flag:pinkFlag
          },
          {
            titleEN:"Bread", 
            titleBG:"Хляб", 
            flag:grayFlag
          },
          {
            titleEN:"Appetizers",
            titleBG:"Мезета", 
            flag:lightPinkFlag
          },
          {
            titleEN:"Pasta", 
            titleBG:"Паста", 
            flag:grayFlag
          },
          {
           titleEN:"Pizza", 
           titleBG:"Пица", 
           flag:darkGrayFlag
          },
          {
            titleEN:"Toasts and Burgers",
            titleBG:"Тостове и Бургери",
            flag:redFlag
          },
          {
            titleEN:"Desserts", 
            titleBG:"Десерти", 
            flag:deepRedFlag
          },
          {
            titleEN:"Drinks", 
            titleBG:"Напитки", 
            flag:brownFlag
          },
          {
            titleEN:"Alergens",
            titleBG:"Алергени",
            flag:""
          }
        ]
        this.activeSubmenuLabel = this.menuSections[0].titleEN.split(" ").join("").toLocaleLowerCase();
        this.menuContents = <MenuSection titleEN={this.menuSections[0].titleEN} titleBG={this.menuSections[0].titleBG} flag={this.menuSections[0].flag}></MenuSection>;
    }
    onSubMenuSelected(element){
      //console.log(element);
      this.activeSubmenuLabel = element.titleEN.split(" ").join("").toLocaleLowerCase();
      
      this.menuContents = <MenuSection titleEN={element.titleEN} titleBG={element.titleBG} flag={element.flag}></MenuSection>
      
      this.setState({
        activeSubmenuLabel:element.titleEN.split(" ").join("").toLocaleLowerCase(),
        menuContents: <MenuSection titleEN={element.titleEN} titleBG={element.titleBG} flag={element.flag}></MenuSection>
      })
    }
    renderContent(){
      
      let res;
      res = this.menuContents
      return(res)
    }
    render() {
        return (
          <div>
            <div>
              <div id="menuDetailsContainer" className={this.styles.menuHeaderTitleLabel}>{this.textDB("menu")}</div>
              {!isMobile ? 
                <div className="row">
                  <div className={"col-4 "+ this.styles.subMenuContainer} style={{width:"20%"}}>
                    {this.menuSections.map((element,i) =>{
                          return(
                            <p className={
                                this.activeSubmenuLabel === element.titleEN.split(" ").join("").toLocaleLowerCase() ? this.styles.subMenuItemPActive : this.styles.subMenuItemP
                              } 
                              key={i} 
                              onClick={(el)=>{
                                this.onSubMenuSelected(element);
                            }}>
                              {this.props.i18n.language === "gb" ? element.titleEN : element.titleBG}
                              </p>
                          )
                    })}
                  </div>
                  <div className="col-8" style={{width:"80%"}}>
                    {this.renderContent()}
                  </div>
                </div> 
              :
                <div className="row">
                  <div>
                    <Carousel 
                      swipeable={true}
                      draggable={false}
                      showDots={false}
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={2000}
                      keyBoardControl={true}
                      focusOnSelect={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                      afterChange={(previousSlide, { currentSlide, onMove }) => {
                        //console.log(currentSlide);
                        //let test = this.menuSections[17];
                        //console.log("length is "+this.menuSections.length);
                        //console.log(test);
                        if(this.menuSections[currentSlide]){
                          this.onSubMenuSelected(this.menuSections[currentSlide]);
                        }else{

                        }
                        
                      }}
                      responsive={this.carouselSettings}>
                        {this.menuSections.map((element, i) => {
                            return (
                              <div key={i} id={element.titleEN.split(" ").join("").toLocaleLowerCase()} >
                                  <p className={ 
                                    this.activeSubmenuLabel === element.titleEN.split(" ").join("").toLocaleLowerCase() ?
                                      this.styles.subMenuItemPActiveMobile 
                                    : 
                                    this.styles.subMenuItemP }>
                                      {this.props.i18n.language === "gb" ? element.titleEN : element.titleBG}
                                  </p>
                              </div>
                            ) 
                        })}
                  </Carousel>
                  </div>
                  <div style={{marginTop:"20px"}}>
                    {this.renderContent()}
                  </div>
                </div>
              }
            </div>
            <div>
            </div>
          </div>
        )
    }

}

export default withTranslation()(Menu); 
    