import { useState } from 'react';
import  styles from './Navbar.module.css';
import '../i18n.js';
import LanguageSelector from './LanguageSelector.js';
import { useTranslation } from "react-i18next";
import logo from '../images/logo.png';

function Navbar() {
    // adding the states 
    const [isActive, setIsActive] = useState(false);
    //add the active class
    const toggleActiveClass = () => {
      setIsActive(!isActive);
    };
    //clean up function to remove the active class
    const removeActive = () => {
      setIsActive(false)
    }
    const cleanSelectionAndSelect = (newlySelected) =>{
      for(let i =0; i <= 3; i++){
        let el = document.getElementById(i.toString());
        el.classList.remove("navLinkSelected");
      }
      newlySelected.classList.add("navLinkSelected");
      
      switch(newlySelected.id){
        case "0":
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          break;
        case "1":
          document.getElementById("menuDetailsContainer").scrollIntoView();
          break;
        case "2":
          document.getElementById("aboutUsDetailsContainer").scrollIntoView();
          break;
        case "3":
          document.getElementById("contactUsContainerDetails").scrollIntoView();
          break;
        default:
          break;
      }
    }

    const { t, i18n } = useTranslation();

    return (
        <header style={{height:"180px"}} className="App-header">
          <nav className={`${styles.navbar}`}>
            {/* logo */}
            <a className={`${styles.logo}`}>
              <img src={logo}></img>
            </a>
            <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
              <li onClick={removeActive}>
                <a id="0" className={`${styles.navLink}`} onClick={(el)=>{
                  cleanSelectionAndSelect(el.currentTarget);
                }}>{t("home")}</a>
              </li>
              <li onClick={removeActive}>
                <a id="1" className={`${styles.navLink}`} onClick={(el)=>{
                  cleanSelectionAndSelect(el.currentTarget);
                }}>{t("menu")}</a>
              </li>
              <li onClick={removeActive}>
                <a id="2" className={`${styles.navLink}`} onClick={(el)=>{
                  cleanSelectionAndSelect(el.currentTarget);
                }}>{t("aboutus")}</a>
              </li>
              <li onClick={removeActive}>
                <a id="3" className={`${styles.navLink}`} onClick={(el)=>{
                  cleanSelectionAndSelect(el.currentTarget);
                }}>{t("contact")}</a>
              </li>
              <li>
              <LanguageSelector/>
              </li>
            </ul>
            <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
              <span className={`${styles.bar}`}></span>
              <span className={`${styles.bar}`}></span>
              <span className={`${styles.bar}`}></span>
            </div>
          </nav>
        </header>
    );
  }
  export default Navbar;
  