import React, { Component } from "react";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../images/logo.png';
import { withTranslation } from 'react-i18next';

class WhatsappIntegration extends Component {
    constructor(props){  
    super(props);
    this.textDB = this.props.t
    }
    render() {
        return (
            <FloatingWhatsApp 
                phoneNumber="+359886944381"
                accountName="Geri Sangria"
                avatar={logo}
                darkMode={true}
                statusMessage={this.textDB("whatsappp.statusMessage")}
                chatMessage={this.textDB("whatsappp.chatMessage")}
                placeholder={this.textDB("whatsappp.placeholder")}
                allowEsc
                allowClickAway
                notification
            />
        )
    }
}

export default withTranslation()(WhatsappIntegration);