import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import  styles from './ContactUs.module.css';
import emailjs from '@emailjs/browser';
import { PhoneIcon, MapPinIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import facebookIcon from "../images/facebook.svg";

class ContactUs extends Component {
    constructor(props){  
    super(props);
    this.textDB = this.props.t;
    this.wtf = this.props.i18n;
    this.styles = styles;
    this.emailjs = emailjs;
    this.emailSenderSettings = {
        serviceId: "service_1vtekik",
        templateId: "template_i9aoctw",
        publicKey: "d8YYJ5rvQi-_ak18y"
    };
    
    this.showAlert = "none";
    this.showAlertMessage = true;

    this.onSubmit = this.onSubmit.bind(this); 
    this.closeAlert = this.closeAlert.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }
   
    onLanguageChanged(lng){
        //console.log("here");
    }

    closeAlert(){
        this.showAlert = "none";
        this.setState({showAlert: "none"});
    }
    showPopup(flag){
        this.showAlert = "block";
        this.showAlertMessage = flag;
        this.setState({showAlertMessage: flag, showAlert:"block"});
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    onSubmit(e){
        e.preventDefault();
        this.emailjs.sendForm(this.emailSenderSettings.serviceId, this.emailSenderSettings.templateId, e.target, this.emailSenderSettings.publicKey)
          .then((result) => {
            console.log(result.text);
            this.showPopup(true);
          }, (error) => {
            console.log(error.text);
            this.showPopup(false); //message failed with reason..
          });
          e.target.reset();
    }
    render() {
        return (
            <div id="contactUsContainerDetails" className={this.styles.info}>
                <div className={this.styles.contactUsTitleCnt}>
                    <label style={{fontSize:"40px"}}><b>{this.textDB("contact")}</b></label>
                </div>
                <div>
                    <div className={this.styles.formAlert} style={{display:this.showAlert}}>
                        {this.showAlertMessage ? 
                                <h3>{this.textDB("contactusAlertMessageSuccess")} </h3> 
                            : 
                                <h3>{this.textDB("contactusAlertMessageFail")}</h3>}
                        <button onClick={this.closeAlert}>Close</button>
                    </div>
                    <div style={{float:"left",width:"50%"}}>
                        <form className={this.styles.formContainer} onSubmit={this.onSubmit}>
                            <div className={this.styles.formElement}>
                                <label htmlFor="from_name">{this.textDB("contactusName")}</label>
                                <input type="text" id="from_name" name="from_name" placeholder={this.textDB("contactusInputName")} required />
                            </div>

                            <div className={this.styles.formElement}>
                                <label>{this.textDB("contactusEmail")}</label>
                                <input type="email" id="from_email" name="from_email" placeholder={this.textDB("contactusInputEmail")} required />
                            </div>

                            <div className={this.styles.formElement}>
                                <label>{this.textDB("contactusPhone")}</label>
                                <input type="text" id="from_phone" name="from_phone" placeholder={this.textDB("contactusInputPhone")} required />
                            </div>

                            <div className={this.styles.formElement}>
                                <label htmlFor="message">{this.textDB("contactusMessage")}</label>
                                <textarea name="message" rows="8" cols="30" placeholder={this.textDB("contactusInputMessage")} required />
                            </div>
                            <button type='submit' className={this.styles.formButton} >{this.textDB("contactusSubmit")}</button>
                        </form>
                    </div>
                    <div style={{float:"left",width:"50%",minWidth:"420px"}}>
                        <div style={{textAlign:"left",paddingLeft:"4px", paddingTop:"8px"}}>
                            <span>
                                <MapPinIcon style={{width:"20px"}}></MapPinIcon>
                            </span>
                            <span style={{verticalAlign:"top", marginLeft:"6px"}}>
                                <label style={{color:"black"}}>ul."Borovets" 102, 2010 Borowez</label>
                            </span>
                        </div>
                        <div style={{textAlign:"left",paddingLeft:"6px", paddingTop:"8px"}}>
                            <span>
                                <PhoneIcon style={{width:"20px"}}></PhoneIcon>
                            </span>
                            <span style={{verticalAlign:"top", marginLeft:"6px"}}>
                                <a style={{color:"black",textDecoration:"none",fontSize:"16px"}} href="tel:+359886944381">+359 886 944 381</a>
                            </span>
                        </div>
                        <div style={{textAlign:"left",paddingLeft:"6px", paddingTop:"8px"}}>
                            <span>
                                <EnvelopeIcon style={{width:"20px"}}></EnvelopeIcon>
                            </span>
                            <span style={{verticalAlign:"top", marginLeft:"6px"}}>
                                <a style={{color:"black",textDecoration:"none",fontSize:"16px"}} href="mailto:home@sangria.bg">home@sangria.bg</a>
                            </span>
                        </div>
                        <div style={{textAlign:"left",paddingLeft:"0px", paddingTop:"8px"}}>
                            <span style={{cursor:"pointer"}}>
                                <a href="https://www.facebook.com/sangria.bg" target="_blank">
                                    <img style={{width:"30px"}} src={facebookIcon}></img>
                                </a>
                            </span>
                        </div>
                        <div className={this.styles.mapContainer}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d930.0342606352009!2d23.60743670958413!3d42.26411877908366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40af436037623ab9%3A0x16d6837204c44400!2sSangria%20resort%20Borovets!5e0!3m2!1sen!2sbg!4v1718894489162!5m2!1sen!2sbg" width="100%" height="100%" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ContactUs); 